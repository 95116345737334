"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.staticRenderFns = exports.render = void 0;
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "kiwi-messageinfo",
    on: {
      "click": function click($event) {
        $event.stopPropagation();
      }
    }
  }, [_vm.message.mentioned_urls.length > 0 ? _c('div', {
    staticClass: "kiwi-messageinfo-urls"
  }, _vm._l(_vm.message.mentioned_urls, function (url) {
    return _c('div', {
      key: url,
      staticClass: "kiwi-messageinfo-url"
    }, [_vm.showLinkPreviews ? _c('a', {
      staticClass: "u-button u-button-secondary",
      on: {
        "click": function click($event) {
          return _vm.urlPreview(url);
        }
      }
    }, [_vm._v("Preview")]) : _vm._e(), _c('a', {
      staticClass: "u-link",
      attrs: {
        "href": url,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(" " + _vm._s(url) + " ")])]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "kiwi-messageinfo-actions",
    class: {
      'kiwi-messageinfo-actions--open': _vm.requestingInput
    }
  }, [!_vm.requestingInput && _vm.message.nick && _vm.buffer.name !== _vm.message.nick && !_vm.isSelf() ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-reply",
    on: {
      "click": _vm.openQuery
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('reply_in_private')) + " ")]) : _vm._e(), _vm.message.user && _vm.areWeAnOp() && !_vm.isSelf() ? _c('div', {
    staticClass: "kiwi-messageinfo-opbuttons"
  }, [_c('input-prompt', {
    attrs: {
      "label": "Kick reason:"
    },
    on: {
      "submit": _vm.onKick,
      "cancel": function cancel($event) {
        _vm.requestingInput = false;
      }
    }
  }, [!_vm.requestingInput ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-kick-user",
    on: {
      "click": function click($event) {
        _vm.requestingInput = true;
      }
    }
  }, [_vm._v(" Kick " + _vm._s(_vm.message.nick) + " ")]) : _vm._e()]), _c('input-prompt', {
    attrs: {
      "label": "Ban reason:"
    },
    on: {
      "submit": _vm.onBan,
      "cancel": function cancel($event) {
        _vm.requestingInput = false;
      }
    }
  }, [!_vm.requestingInput ? _c('a', {
    staticClass: "u-link kiwi-messageinfo-ban-user",
    on: {
      "click": function click($event) {
        _vm.requestingInput = true;
      }
    }
  }, [_vm._v(" Ban " + _vm._s(_vm.message.nick) + " ")]) : _vm._e()])], 1) : _vm._e()])]);
};
exports.render = render;
var staticRenderFns = [];
exports.staticRenderFns = staticRenderFns;